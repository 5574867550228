.calendar {
  max-width: 800px;
  padding: 20px 16px;
  border-bottom: 2px solid rgb(230, 230, 230, 0.7);
}

a {
  text-decoration: none;
  color: inherit;
}

.calendar__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  border: none;
}

.calendar__title {
  font-weight: 500;
}

.calendar__change-button {
  margin: 2px;
  border-color: #586bff;
}

.calendar__change-button span {
  color: #586bff;
}

.calendar-row {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto;
  gap: 1px;
  margin: 0 auto;
  padding: 0;
  text-align: center;
}

.calendar-row__dayName {
  font-weight: 200;
  margin: 0 auto;
  font-size: 15px;
}

.calendar-day {
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin: 0.5rem auto;
  width: 30px;
  position: relative;
  height: 30px;
}

.calendar-day__date {
  font-weight: 500;
  font-size: 15px;
}

.calendar-day__event {
  font-size: 15px;
  height: 20px;
  margin-top: -11px;
  color: rgb(0, 0, 0, 0.7);
  position: absolute;
  bottom: -55%;
  left: 50%;
  transform: translateX(-50%);
}

.calendar-day__info {
  border: none;
  background: none;
  position: relative;
  width: 100%;
  height: 100%;
}

.calendar-day__info > div {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.selected-circle {
  background-color: #586bff;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
